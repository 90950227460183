export const ACQUA = '#41c9c6'
export const DARK_GREEN = '#003e46'
export const GREEN = '#04928e'
export const LIGHT_GREEN = '#d0e96d'
export const DARK_GRAY = '#58595b'
export const GRAY = '#e1e2e3'
export const LIGHT_GRAY = '#f1f2f2'
export const BLUE = '#00bad5'
export const ORANGE = '#f26522'
export const RED = '#d50000'
export const BLACK = '#707070'
export const WHITE = '#FFF'

export const PRIMARY = ACQUA
export const ACTIVE = LIGHT_GREEN
export const HOVER = PRIMARY
export const CLICK = BLUE
export const DISABLED = LIGHT_GRAY
export const TEXT = BLACK
