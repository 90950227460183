import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FONT_FAMILY_2 } from '../../constants/FontFamily';
import { WHITE, TEXT, ORANGE, LIGHT_GREEN, DARK_GREEN } from '../../constants/Colors';

const getColor = ({reversed, secondary}) => {
    if(reversed) return WHITE;
    if(secondary) return DARK_GREEN;
    return TEXT;

}

const P = styled.p`
font-family: ${FONT_FAMILY_2};
font-size: 16;
font-weight: ${({bold}) => bold ? 'bold' : 'normal'};
line-height: 1.69;
color: ${(props) => getColor(props)};

& strong{
    font-weight: bold;
    color: ${({reversed}) => reversed ? LIGHT_GREEN : ORANGE};
}
`;

const Text = ({children, reversed, ...props}) => {
    return <P reversed={reversed} {...props}>{children}</P>
};

Text.defaultProps = {
    reversed: false
}

Text.propTypes = {
    reversed: PropTypes.bool
}


export default Text;