import React from 'react'
import { GREEN } from '../../constants/Colors';
import styled from 'styled-components'

const imageStyle = (active) => {
    return !active ? `&:before {
        content: "";
        position: absolute;
        top: 11px;
        left: 10px;
        width: 32px;
        height: 6px;
        border-top: 16px double #FFF;
        border-bottom: 5px solid #FFF;
      }` :
      `&:before {
        content: "";
        position: absolute;
        top: calc(50% - 18px);
        left: calc(50% - 12px);
        width: 24px;
        height: 24px;
        border: 0px solid #FFF;
        background-color: #FFF;
        transform: rotate(45deg);
      }
      &:after {
        content: "";
        position: absolute;
        top: calc(50% - 28px);
        left: calc(50% - 12px);
        width: 24px;
        height: 24px;
        border: 0px solid ${GREEN};
        background-color: ${GREEN};
        transform: rotate(45deg);
      }`

}

const Button = styled.button`
  position: ${({position}) => position || 'relative'};
  top: ${({top}) => top || 'auto'};
  left: ${({left}) => left || 'auto'};
  right: ${({right}) => right || 'auto'};
  padding: 0;
  border: 0;
  background-color: ${GREEN};
  width: 64px;
  height: 64px;
  cursor: pointer;
  border: 6px solid ${GREEN};
  overflow: hidden;

  @media screen and (min-width: 946px){
    display: none;
  }

  ${({active}) => imageStyle(active)}
  
`

class MenuButton extends React.PureComponent {

    state = {active: false}

    handleClick = () => {
        this.setState({active: !this.state.active}, () => {
                this.state.active ? this.props.whenActive() : this.props.whenInactive();
            }
        );
    }

    inactivate = () => {
        this.setState({active: false});
        this.props.whenInactive();
    }
    
    render = () => {
        return (
            <Button 
                {...this.props}
                active={this.state.active} 
                onClick={this.handleClick} 
                onBlur={this.inactivate}/>
        );
    }
}

export default MenuButton